import {acceptHMRUpdate, defineStore} from "pinia";
import qs from 'qs';
import {useApi} from "~/store/app/api";

export const usePrices = defineStore("prices", {
    state: () => {
        const config = useRuntimeConfig();

        return {
            apiBase: config.public.apiBase,
            cache: config.public.cache,
            listPrices: [],
            listPricesGroup: [],
            textSearch: "",
        };
    },

    getters: {
        filterByOnline: (state) => (listPrices, online) => {
            return listPrices.filter(priceId => {
                const price = state.getPriceById(priceId);
                return price.attributes.online === online;
            })
        },
        checkOnline: (state) => (priceId) => {
            const price = this.getPriceById(priceId);
            return price.attributes.online;
        },
        getPriceById: (state) => (priceId) => {
            return state.listPrices.find((item) => +item.id === +priceId);
        },
        filterListPrices: (state) =>
            state.listPrices.filter((price) => {
                if (state.textSearch.length < 3) return true;
                if (
                    price.attributes.title
                        .toLowerCase()
                        .indexOf(state.textSearch.toLowerCase().trim()) !== -1
                ) {
                    return true;
                }
                for (let tag of price.attributes.Tags) {
                    if (tag.Tag === null) {
                        return false;
                    }
                    if (
                        tag.Tag.toLowerCase().indexOf(
                            state.textSearch.toLowerCase().trim()
                        ) !== -1
                    ) {
                        return true;
                    }
                }
            }),
        createApiUrl: (state) => (url) => {
            return `${state.apiBase}${url}`;
            // return `${state.apiBase}${url}${
            //     state.cache ? "" : "&" + new Date().getTime()
            // }`;
        },
        priceInClinic: (state) => (priceId, clinicId) => {
            const price = state.getPriceById(priceId);

            return price?.attributes?.price.filter(
                (item) => item?.clinic?.data?.attributes?.clinicId === clinicId
            )[0]?.Price;
        },
    },

    actions: {
        async fetchPrices(extended = false) {
            const filters = {
                // link: {
                //     $eq: link,
                // },
            };
            let populate = '*';
            if (extended) {
                populate = {
                    specials: {
                        populate: '*',
                    },
                    docs: {
                        // populate: 'deep,1',
                        // clinic: 'deep,2',
                        populate: {
                            photo_mini: {
                                fields: ['url'],
                            },
                            photo: {
                                fields: ['url'],
                            },
                            clinics: {
                                fields: ['clinicId', 'region']
                            },
                            fields: ['link']
                        }
                    },
                    price: {
                        populate: {
                            clinic: {
                                fields: ['clinicId', 'region']
                                // $contains: 'msk'
                            },
                        },
                    },
                };
            }
            const cache = '&cache=get';
            const query = qs.stringify({filters, populate, pagination: {pageSize: 1000}}, {encode: true});
            const url = `/api/prices?${query}${cache}`;

            const startTime = performance.now();
            // const url = `/api/prices?populate=deep,3&pagination[pageSize]=300&sort=title`;
            // const url = `/api/prices?populate[price]populate[clinic]=*&pagination[pageSize]=300&sort=title`;
            const listPrices = await fetch(
                this.createApiUrl(
                    url
                )
            )
                .then((response) => response.json())
                .then((result) => result.data)
                .catch((error) => console.log("error", error));

            const endTime = performance.now();
            const durationInSeconds = (endTime - startTime) / 1000;
            this.listPrices = [...listPrices];
            console.log(`${durationInSeconds} - ${url}`);
        },
        async fetchPrice(priceId) {
            const startTime = performance.now();
            const url = `/api/prices/${priceId}?populate=deep,3`;
            const price = await fetch(
                this.createApiUrl(url)
            )
                .then((response) => response.json())
                .then((result) => result.data)
                .catch((error) => console.log("error", error));
            if (Object.values(price).length) {
                const endTime = performance.now();
                const durationInSeconds = (endTime - startTime) / 1000;
                console.log(`${durationInSeconds} - ${url}`);
                return price;
            } else {
                console.log("Price is empty");
            }
        },
        addPriceScore(id) {
            let priceIdx = this.listPrices.findIndex((item) => item.id === id);
            if (this.listPrices[priceIdx].score) {
                this.listPrices[priceIdx].score += 300;
            } else {
                this.listPrices[priceIdx].score = 300;
            }
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePrices, import.meta.hot));
}
